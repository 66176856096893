/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetMenuContentListResponse } from '../models/get-menu-content-list-response';
import { getMenuSections } from '../fn/menu/get-menu-sections';
import { GetMenuSections$Params } from '../fn/menu/get-menu-sections';
import { GetMenuSectionsResponse } from '../models/get-menu-sections-response';
import { getSectionContentList } from '../fn/menu/get-section-content-list';
import { GetSectionContentList$Params } from '../fn/menu/get-section-content-list';
import { getSectionContentListByCode } from '../fn/menu/get-section-content-list-by-code';
import { GetSectionContentListByCode$Params } from '../fn/menu/get-section-content-list-by-code';


/**
 * Služby na načítanie sekcií pre navigačné menu
 */
@Injectable({ providedIn: 'root' })
export class MenuService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMenuSections()` */
  static readonly GetMenuSectionsPath = '/client/menu';

  /**
   * Služba pre načítanie sekcií pre navigačné menu.
   *
   * Načíta sekcií pre navigačné menu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuSections()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSections$Response(params?: GetMenuSections$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMenuSectionsResponse>> {
    return getMenuSections(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie sekcií pre navigačné menu.
   *
   * Načíta sekcií pre navigačné menu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuSections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSections(params?: GetMenuSections$Params, context?: HttpContext): Observable<GetMenuSectionsResponse> {
    return this.getMenuSections$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMenuSectionsResponse>): GetMenuSectionsResponse => r.body)
    );
  }

  /** Path part for operation `getSectionContentList()` */
  static readonly GetSectionContentListPath = '/client/menu/{id}/contentList';

  /**
   * Služba pre načítanie zoznamu stránok.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSectionContentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentList$Response(params: GetSectionContentList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMenuContentListResponse>> {
    return getSectionContentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie zoznamu stránok.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSectionContentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentList(params: GetSectionContentList$Params, context?: HttpContext): Observable<GetMenuContentListResponse> {
    return this.getSectionContentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMenuContentListResponse>): GetMenuContentListResponse => r.body)
    );
  }

  /** Path part for operation `getSectionContentListByCode()` */
  static readonly GetSectionContentListByCodePath = '/client/menuByCode/{code}/contentList';

  /**
   * Služba pre načítanie zoznamu stránok.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa kodu (kod ukazuje na staticke sekcie, hlavicka hlavna str atd)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSectionContentListByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentListByCode$Response(params: GetSectionContentListByCode$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMenuContentListResponse>> {
    return getSectionContentListByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie zoznamu stránok.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa kodu (kod ukazuje na staticke sekcie, hlavicka hlavna str atd)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSectionContentListByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentListByCode(params: GetSectionContentListByCode$Params, context?: HttpContext): Observable<GetMenuContentListResponse> {
    return this.getSectionContentListByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMenuContentListResponse>): GetMenuContentListResponse => r.body)
    );
  }

}
